import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

const DisplaySurvey = () => {
  const { schemaId } = useParams(); // Get schemaId from path
  const { formId } = useParams(); // Get formId from path
  const location = useLocation(); // Get query params
  const [ completedOn, setCompletedOn ] = useState('')

  const [survey] = useState(new Model());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log("schemaId", schemaId)
  console.log("formId", formId)

  // Fetch schema data and configure survey
  useEffect(() => {
    const fetchSchema = async () => {
      try {
        const schemaData = await fetch(`${import.meta.env.VITE_API_URL}/api/forms/schema/${schemaId}`)
        const data = await schemaData.json()
        console.log(data)
        survey.setJsonObject(data.content);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (schemaId) fetchSchema();
  }, [schemaId]);

  // Fetch survey
  useEffect(() => {
    const fetchForm = async () => {
      try {
        const formData = await fetch(`${import.meta.env.VITE_API_URL}/api/forms/${formId}`)
        const data = await formData.json()
        survey.data = data.content
        if (data.content.status == "Completed") {
          survey.mode = "display"
          setCompletedOn(data.createdAt)
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (formId) fetchForm();
  }, [formId]);
  

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <Survey model={survey} />
      <div className=''>
        <p>Completed On: { completedOn }</p>
      </div>
    </div>
  );
};

export default DisplaySurvey;
