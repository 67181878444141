import { BlobServiceClient } from "@azure/storage-blob";

// Blob service configuration (store these securely)
const accountName = import.meta.env.VITE_SA_ACCOUNT_NAME;
const sasToken = import.meta.env.VITE_SA_SAS_TOKEN;
const containerName = import.meta.env.VITE_SA_CONTAINER_NAME;

// Create a single instance of BlobServiceClient
const blobServiceClient = new BlobServiceClient(
  `https://${accountName}.blob.core.windows.net/?${sasToken}`
);

const sanitizeBlobName = (fileName) => {
  const maxBlobNameLength = 5120; // Blob name limit
  const timestamp = new Date().getTime(); // Add a unique timestamp
  const cleanFileName = fileName.slice(-200); // Keep the last 200 characters of the file name
  const blobName = `${timestamp}-${cleanFileName}`; // Combine timestamp with file name

  return blobName.slice(0, maxBlobNameLength); // Ensure final name is within limits
};

const getContainerClient = (containerName) => {
  return blobServiceClient.getContainerClient(containerName);
};

export const uploadFileToAzure = async (file) => {
  try {
    const containerClient = getContainerClient(containerName);
    
    // const blobName = `${new Date().getTime()}-${file.name}`;
    const blobName = sanitizeBlobName(file.name);
    console.log(`Blob Name: ${blobName} (Length: ${blobName.length})`);
    console.log(`Container Name: ${containerName} (Length: ${containerName.length})`);

    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    await blockBlobClient.uploadBrowserData(file);
    console.log("File uploaded successfully:", blobName);

    return blockBlobClient.url; // Return the file URL
  } catch (error) {
    console.error("Error uploading file to Azure:", error);
    throw error;
  }
}